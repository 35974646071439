import React, { lazy, Suspense } from 'react';
import SuspenseLoader from './components/Loaders/Suspense';

const Loader = (Component: React.FC) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const HomePage = Loader(lazy(() => import('./pages/Home')));
const ConsultationPage = Loader(lazy(() => import('./pages/Consultation')));
const routes = () => [
  {
    path: '/',
    element: <HomePage />,
  },
  // {
  //   path: '/consultation',
  //   element: <ConsultationPage />,
  // },
];

export default routes;
